<template>
  <v-row>
    <v-col cols="12" class="py-0 px-0">
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table task-table"
      >
        <template v-slot:item.description="{ item }">
          <span class="form-link" @click="open(item)">
            {{ item.description }}
          </span>
        </template>

        <template v-slot:item.execute="{ item }">
          <template v-if="item.execute">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </template>
          <template v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="runTask(item)"
                >
                  mdi-play
                </v-icon>
              </template>
              <span>Run</span>
            </v-tooltip>
          </template>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-menu
            v-model="item.deleteMenu"
            :close-on-content-click="false"
            rounded
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small icon color="bad-2" v-on="on" v-bind="attrs">
                <v-icon x-small>fas fa-trash-alt</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list width="350">
                <v-list-item>
                  <v-list-item-content class="pb-0">
                    <v-list-item-title
                      >Are you sure you want to delete this Task?
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-right mt-2">
                      <v-btn
                        rounded
                        text
                        color="tertiary"
                        small
                        class="text-capitalize"
                        @click="cancelDelete(item)"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        text
                        color="bad-2"
                        small
                        class="text-capitalize"
                        @click="deleteTask(item)"
                      >
                        Delete
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :width="headers.width"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          ></td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
import { taskExecute } from "@/api/system/task";
import { Errors } from "@/utils/error";

export default {
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],

      page: 1,
      pageTotalCount: 1,
      size: 1,

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
    auditorTaskProcessing: {
      get() {
        return this.$store.state.synapsa.auditorTaskProcessing;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "auditorTaskProcessing",
          value: val,
        });
      },
    },
    auditorRulesetTaskProcessing: {
      get() {
        return this.$store.state.synapsa.auditorRulesetTaskProcessing;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "auditorRulesetTaskProcessing",
          value: val,
        });
      },
    },
    auditorCveTaskProcessing: {
      get() {
        return this.$store.state.synapsa.auditorCveTaskProcessing;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "auditorCveTaskProcessing",
          value: val,
        });
      },
    },
    auditorIntegrityTaskProcessing: {
      get() {
        return this.$store.state.synapsa.auditorIntegrityTaskProcessing;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "auditorIntegrityTaskProcessing",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue !== undefined &&
          newValue !== this.sizes.indexOf(this.tableSize)
        ) {
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
  },
  created() {
    this.location = window.location.origin;
  },
  methods: {
    runTask(task) {
      if (
        ["auditor:integrityCheck", "auditor:cve", "auditor:ruleset"].some(
          (cmd) => task.command === cmd
        )
      ) {
        // Side menu
        this.auditorTaskProcessing = true;
      }

      if (task.command === "auditor:integrityCheck") {
        this.auditorIntegrityTaskProcessing = true;
      } else if (task.command === "auditor:cve") {
        this.auditorCveTaskProcessing = true;
      } else if (task.command === "auditor:ruleset") {
        this.auditorRulesetTaskProcessing = true;
      }

      task.execute = true;
      taskExecute(task.id)
        .then((response) => {
          task.execute = false;
          task.average_runtime = response.average_runtime;
          task.nextRun = response.nextRun;
          task.lastRun = response.lastRun;

          this.$toast.open({
            message: "Task was executed!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
        })
        .catch((e) => {
          Errors.show(e);
          task.execute = false;
        });
    },
    open(item) {
      this.idAddForm = item.id;
      this.keywordAddForm = "systemtask";
    },
    cancelDelete(item) {
      this.$emit("cancelDelete", item);
    },
    deleteTask(item) {
      this.$emit("deleteTask", item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
